<template>
<!-- 获取网址state参数 -->
  <div></div>
</template>

<script>
import {loginState} from '@/assets/js/api'
export default {
data(){
    return{

    }
},
methods:{

},
created(){
    let roundNum = this.$route.query.state;
    loginState(roundNum).then(res =>{
        if(res.data.code === 200 && res.data.result){
          let UserInfo = res.data.result.userInfo;
          let con = {
              realname:UserInfo.realname,
              avatar:UserInfo.avatar,
              token:res.data.result.token
          }
          localStorage.setItem('UserInfo',JSON.stringify(con));
          let path = localStorage.getItem(roundNum) ? localStorage.getItem(roundNum) : '/homeData';
          console.log(path,'-=-=');
          console.log(roundNum,'999')
          this.$router.push(path);

        }
      })
}
}
</script>

<style>

</style>